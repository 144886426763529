/* Main styles. */
body {
  -webkit-font-smoothing: antialiased;
  font-display: swap;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.2rem;
}

header,
h1,
footer {
  font-display: swap;
  font-family: "press-start"
}

footer section {
  margin-top: 0.5rem;
}

h1,
.centered {
  text-align: center;
}

section.skills {
  margin-left: 1rem;
}

h2 span,
section.role h3 span {
  display: block;
  font-size: 0.8rem;
  font-style: italic;
  font-weight: initial;
}

article .experience h2 span {
  display: block;
  font-size: 0.8rem;
  font-style: italic;
  font-weight: initial;
}

ul,
ol {
  padding-inline-start: 1rem;
  margin-left: 1rem;
}

footer {
  border-image-slice: 1;
  border-image-source: linear-gradient(90deg, #ccc, #108de0, #ccc);
  border-top: 1px solid;
  border: 0;
  font-size: 0.75rem;
  margin-top: 1rem;
  padding-top: 1rem;
  text-align: center;
}

.paginator {
  display: flex;
  border-top: 1px solid;
  margin-top: 1rem;
}

.paginator a.next {
  margin-left: auto;
}
